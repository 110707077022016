import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import { ContractForm } from '@pages/employees/create-contractor/contract-form/contract-form'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useBoolean } from 'usehooks-ts'

export const UpdateContractModal = ({ employee, showUpdateContractModal, withSelectCancelButton }) => {
  const [activeStep, setStep] = useState(1)
  const updateContractOpen = useBoolean(false)

  const handleUpdateContractClose = () => {
    showUpdateContractModal.setFalse()
    updateContractOpen.setFalse()
  }

  if (!showUpdateContractModal.value) {
    return null
  }
  return (
    <Modal show onClose={handleUpdateContractClose} fullscreen>
      <Modal.Body className="p-0">
        <Stepper
          activeStep={activeStep}
          title="Updating contract"
          description={
            <>
              The current contract will be deactivated <br /> and replaced by the updated version.
            </>
          }
          sidebarStyle={{ width: 400 }}
        >
          <Step step="1" title="Update contract" setStep={setStep}>
            <StepContent title="Update contract" onClose={handleUpdateContractClose}>
              <ContractForm
                updateContractOpen={updateContractOpen}
                contractor={employee}
                onClose={handleUpdateContractClose}
                showUpdateContractModal={showUpdateContractModal}
                withSelectCancelButton={withSelectCancelButton}
              />
            </StepContent>
          </Step>
        </Stepper>
      </Modal.Body>
    </Modal>
  )
}
