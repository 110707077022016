import { fetchStats } from '@services/stats.service'
import { useEffect } from 'react'
import { useQuery } from 'react-query'

export const useKompassifySegments = (hasKompassifyInit, clientCountry) => {
  const { data: stats } = useQuery('stats', {
    queryFn: () => fetchStats(),
  })

  // Add default segment
  useEffect(() => {
    if (!hasKompassifyInit) {
      return
    }
    window.kompassifySegmentation.addUserToSegment('Client')
  }, [hasKompassifyInit])

  // Add segment based on stats
  useEffect(() => {
    if (!hasKompassifyInit || !stats) {
      return
    }
    const hasEmployees = Boolean(stats.employees[0].count)
    const hasContractors = Boolean(stats.employees[1].count)
    switch (true) {
      case hasEmployees === true && hasContractors === true: {
        window.kompassifySegmentation.addUserToSegment('Client-Combined')

        break
      }
      case hasEmployees === true && hasContractors === false: {
        window.kompassifySegmentation.addUserToSegment('Client-FTE')

        break
      }
      case hasEmployees === false && hasContractors === true: {
        window.kompassifySegmentation.addUserToSegment('Client-Contractors')

        break
      }
      default:
        break
    }
  }, [hasKompassifyInit, stats])

  // Add segment based on country
  useEffect(() => {
    if (!hasKompassifyInit || !clientCountry) {
      return
    }
    window.kompassifySegmentation.addUserToSegment(`Client-${clientCountry}`)
  }, [hasKompassifyInit, clientCountry])
}
