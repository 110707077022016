import { useEffect, useState } from 'react'

export const useKompassify = (profile) => {
  const [isCompassifyReady, setIsCompassifyReady] = useState(false)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handleKompassifyReady = (event) => {
      if (event.data.TYPE === 'KOMPASSIFY_BOOT_LOADER_IS_READY') {
        setIsCompassifyReady(true)
      }
    }
    window.addEventListener('message', handleKompassifyReady)
    return () => {
      window.removeEventListener('message', handleKompassifyReady)
    }
  }, [])
  useEffect(() => {
    if (!isCompassifyReady || !profile?.id || !profile?.name) {
      return
    }
    window.kommpassifyIdUser(profile?.id)
    window.kompassifyTemplating.addTemplateVariable('client', `${profile?.name}`)
  }, [isCompassifyReady, profile?.id, profile?.name])
  return isCompassifyReady
}
