import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import * as icons from '@core/icons/icons'
import { capitalizeFirstLetter, mapEnum, SplitNumber } from '@core/utils'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { getPayrollById } from 'src/services/payroll.service'
import styled from 'styled-components'

const Styled = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
`

const StyledTable = styled.table`
  tr,
  th {
    height: 32px;
    color: unset;
    border-radius: 4px;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`

const AdditionalPaymentsTrs = ({ payrollId }) => {
  const { data, isFetching } = useQuery('additionalPaymentPayroll', {
    queryFn: () => getPayrollById(payrollId),
    enabled: !!payrollId,
  })

  if (isFetching) {
    return (
      <tr>
        <th>
          <div className="ml-3 mt-2">
            <DotWave size={30} />
          </div>
        </th>
      </tr>
    )
  }

  return data.additional_payments.map(
    ({ id, name, ap_type, amount_in_company_currency, tax_in_company_currency, hide_from_client }) => {
      return (
        <>
          <tr key={id}>
            <th>
              <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                {`${name} (${mapEnum(capitalizeFirstLetter(ap_type))}) :`}
              </Typography>
            </th>
            <td align="right">
              <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                {SplitNumber(amount_in_company_currency || 0)}
              </Typography>
            </td>
          </tr>
          {tax_in_company_currency !== undefined && tax_in_company_currency !== 0 && (
            <tr key={`tax${id}`}>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  {`${name} (${mapEnum(capitalizeFirstLetter(ap_type))}) tax:`}
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                  {hide_from_client ? '-' : SplitNumber(tax_in_company_currency || 0)}
                </Typography>
              </td>
            </tr>
          )}
        </>
      )
    }
  )
}

export default ({ payroll, invoiceTotal, onRequestChange }) => {
  const handleChangeRequest = () => onRequestChange(payroll)

  return (
    <Styled>
      {payroll.state !== 'approved_by_admin' ? (
        <>
          <Typography className="text_regular__14">
            Payroll details including salary, taxes, insurance and other will be available between 10th and 20th of each
            month. You will get an email notification when it’s ready.{' '}
          </Typography>
          <Typography className="text_regular__14">
            Once the payroll is generated from Remofirst’s side you will be able to check all details and approve. Your
            approval will generate an invoice based on payroll details.{' '}
          </Typography>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <Typography className="heading_semibold__18">Payroll #{payroll.id}</Typography>

              {payroll?.month && (
                <Typography className="ml-2 heading_semibold__16">
                  {moment(payroll.month).format('MMMM, YYYY')}
                </Typography>
              )}
            </div>

            <Button
              data-testid="PayrollDetails-B466BE"
              priority="secondary"
              size="small"
              style={{ height: '32px' }}
              disabled={payroll.state !== 'approved_by_admin'}
              onClick={handleChangeRequest}
            >
              <Icon fill="none" icon={icons.navMenuItem} />
              <Typography className="text_light__12 ml-2">Request changes</Typography>
            </Button>
          </div>
          <div style={{ border: '1px dashed #DFDFDF', margin: '12px 0' }} />
          <div className="d-flex justify-content-between">
            <Typography
              className="text_light__12 mb-3 color_text_300 text-uppercase"
              style={{ letterSpacing: '0.4px' }}
            >
              Type
            </Typography>
            <Typography className="text_light__12 mb-3 color_text_300 mr-2" style={{ letterSpacing: '0.4px' }}>
              {payroll?.company_currency?.short_code}
            </Typography>
          </div>
          <StyledTable>
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Gross pay:
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                  {SplitNumber(payroll.gross_pay_in_company_currency)}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Employer tax & contributions:
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                  {SplitNumber(payroll.employer_burden_for_company + payroll.percentage_based_fee)}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Private Insurance:
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                  {SplitNumber(payroll.private_insurance_in_company_currency)}
                </Typography>
              </td>
            </tr>
            {Number(payroll.total_partner_fee_in_company_currency) !== 0 && (
              <tr>
                <th>
                  <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                    Partner fee :
                  </Typography>
                </th>
                <td align="right">
                  <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                    {SplitNumber(payroll.total_partner_fee_in_company_currency || 0)}
                  </Typography>
                </td>
              </tr>
            )}
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Remofirst fee :
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                  {SplitNumber(payroll.total_remofirst_fee_in_company_currency)}
                </Typography>
              </td>
            </tr>
            {Number(payroll.discount_of_remofirst_fee) !== 0 && (
              <tr>
                <th>
                  <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                    Discount of Remofirst fee :
                  </Typography>
                </th>
                <td align="right">
                  <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                    {SplitNumber(payroll.discount_of_remofirst_fee_in_company_currency)}
                  </Typography>
                </td>
              </tr>
            )}
            <AdditionalPaymentsTrs payrollId={payroll.id} />
          </StyledTable>
          <div style={{ border: '1px dashed #DFDFDF', margin: '12px 0' }} />
          {payroll.absences.length > 0 && (
            <div className="d-flex justify-content-between">
              <Typography className="text_regular-normal__14 color_text_300" style={{ letterSpacing: '0.4px' }}>
                Time-off:
              </Typography>
              <div className="d-flex flex-column mb-2">
                {payroll.absences.map((absence) => (
                  <div className="text_medium__14" style={{ letterSpacing: '0.4px' }}>
                    {absence.is_paid ? 'Paid' : 'Unpaid'} Time-off from {moment(absence.start_date).format('MMM Do')}{' '}
                    till {moment(absence.end_date).format('MMM Do')}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between">
            <Typography className="text_regular-normal__14 color_text_300" style={{ letterSpacing: '0.4px' }}>
              Total {invoiceTotal?.company_currency?.short_code}:
            </Typography>
            <b className="text_medium__14" style={{ letterSpacing: '0.4px' }}>
              {`${invoiceTotal?.company_currency?.sign || invoiceTotal?.company_currency?.short_code} ${SplitNumber(
                invoiceTotal?.total_for_company
              )}`}
            </b>
          </div>
        </>
      )}
    </Styled>
  )
}
