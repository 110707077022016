import Typography from '@atoms/Typography/Typography'
import { useMemo } from 'react'
import { Button, CloseButton, Stack } from 'react-bootstrap'
import { MdClose } from 'react-icons/md'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledNotification = styled('div')`
  max-width: 560px;
  border-radius: 6px;
  border: 1px solid #4c92c7;
  background-color: #ebf5fc;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
type ContractUpdateNotificationProps = {
  updateSource: string
  onClick: () => void
  variant?: 'normal' | 'simplified'
}
export const ContractUpdateNotification = ({
  updateSource,
  onClick,
  variant = 'normal',
}: ContractUpdateNotificationProps) => {
  const onClose = () => alert('close')
  const reviewButton = useMemo(
    () => (
      <Button variant="link" className="p-0" onClick={onClick}>
        Review
      </Button>
    ),
    [onClick]
  )
  return (
    <StyledNotification>
      {variant === 'normal' ? (
        <>
          <Stack gap={3} style={{ alignItems: 'flex-start' }}>
            <Typography
              className="heading_semibold__16"
              style={{ textAlign: 'center' }}
            >{`${updateSource} has proposed a contract update`}</Typography>
            <Typography className="text_regular__14">
              Contractor details have been updated. Please regenerate a contract to be legally compliant
            </Typography>
            {reviewButton}
          </Stack>
          <CloseButton onClick={onClose} style={{ alignSelf: 'flex-start' }} />
        </>
      ) : (
        <>
          <Typography className="heading_semibold__16">{`${updateSource} has proposed a contract update`}</Typography>
          {reviewButton}
        </>
      )}
    </StyledNotification>
  )
}
