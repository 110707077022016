import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { helpIcon, infoIcon, infoIconV2, noteIcon } from '@core/icons/icons'
import { SplitNumber } from '@core/utils'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { Styled } from './cost-simulation.styles'

export const CostSimulation = ({ calculations }) => {
  const { in_general_currency } = calculations
  const { currency, employer_costs, fees, gross_salary, total } = in_general_currency
  return (
    <Styled.Root>
      <Typography className="text_regular-normal__14 color_text_300 mb-2">
        Based on the information you have provided, the estimated cost of this hire is as follows:
      </Typography>
      <table className="mb-3">
        <thead>
          <tr>
            <th style={{ width: '46%' }}>Cost breakdown</th>
            <th style={{ width: '27%' }}>Annual | {currency?.short_code}</th>
            <th style={{ width: '27%' }}>Monthly| {currency?.short_code}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Gross salary</strong>
            </td>
            <td>{SplitNumber(gross_salary.annual)}</td>
            <td>{SplitNumber(gross_salary.monthly)}</td>
          </tr>
          <tr>
            <td>
              <strong>Employer tax & contributions</strong>
            </td>
            <td>{SplitNumber(total.annual_total.employer_cost_total)}</td>
            <td>{SplitNumber(total.monthly_total.employer_cost_total)}</td>
          </tr>
          {/* {employer_costs.map(({ name, annual, monthly, summary }) => (
            <Styled.TableRowCost key={name}>
              <td>
                {name}{' '}
                <OverlayTrigger overlay={<Tooltip>{summary}</Tooltip>}>
                  <span>
                    <Icon icon={infoIcon} style={{ fill: '#878787', width: '18px' }} />
                  </span>
                </OverlayTrigger>
              </td>
              <td>{SplitNumber(annual)}</td>
              <td>{SplitNumber(monthly)}</td>
            </Styled.TableRowCost>
          ))} */}
          {fees.map(({ name, annual, monthly, summary }) => (
            <tr key={name}>
              <td>
                <strong>
                  {name}{' '}
                  <OverlayTrigger overlay={<Tooltip>{summary}</Tooltip>}>
                    <span>
                      <Icon icon={infoIcon} style={{ width: '18px' }} />
                    </span>
                  </OverlayTrigger>
                </strong>
              </td>
              <td>{SplitNumber(annual)}</td>
              <td>{SplitNumber(monthly)}</td>
            </tr>
          ))}
          <tr>
            <td>
              <strong>Total*</strong>
            </td>
            <td>{SplitNumber(total.annual_total.total)}</td>
            <td>{SplitNumber(total.monthly_total.total)}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <Typography className="text_regular-normal__14">*The Remofirst fee will be provided separately</Typography>
        <br />
        <Typography className="text_regular-normal__14 color_text_300">
          Please note that these calculations are approximate and are based on today&apos;s tax rates. Calculations will
          only be 100% accurate when processed for each pay period, based on the gross earnings for that period as well
          as the employee&apos;s tax status. These amounts may change if there are additional amounts to be paid.
        </Typography>
        <br />
        <Typography className="text_regular-normal__14 color_text_300">
          You can discuss with our team about the cost later during the employee onboarding process.
        </Typography>
      </div>
    </Styled.Root>
  )
}
