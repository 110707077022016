import './style.scss'

import React from 'react'

export default function Status({ status }) {
  const statusText = status.toLowerCase()
  switch (statusText) {
    case 'draft':
      return <div className="status status_default">Draft</div>
    case 'in_progress':
      return <div className="status status_default">In Progress</div>
    case 'default':
      return <div className="status status_active">Default</div>
    case 'primary':
      return <div className="status status_success">Primary</div>
    case 'succeeded':
      return <div className="status status_success">Successful</div>
    case 'timeoff_pending':
      return <div className="status status_default">Pending</div>
    case 'payment_processing':
      return <div className="status status_active">Processing</div>
    case 'processing':
      return <div className="status status_active">Processing</div>
    case 'pending':
      return <div className="status status_default">Pending</div>
    case 'complete':
      return <div className="status status_success">Complete</div>
    case 'completed':
      return <div className="status status_success">Complete</div>
    case 'app_submitted':
      return <div className="status status_success">Application submitted</div>
    case 'app_accepted':
      return <div className="status status_success">Active</div>
    case 'active':
      return <div className="status status_success">Active</div>
    case 'payment_active':
      return <div className="status status_active">Active</div>
    case 'awaiting_approval':
      return <div className="status status_warning">Awaiting approval</div>
    case 'awaiting_payment':
      return <div className="status status_warning">Awaiting payment</div>
    case 'awaiting_verification':
      return <div className="status status_warning">Awaiting verification</div>
    case 'approved':
      return <div className="status status_success">Approved</div>
    case 'taken':
      return <div className="status status_active">Taken</div>
    case 'onboarding':
      return <div className="status status_active">Onboarding</div>
    case 'invited':
      return <div className="status status_warning">Invited</div>
    case 'pending_your_payment':
      return <div className="status status_warning">Pending your payment</div>
    case 'created':
      return <div className="status status_success">Created</div>
    case 'information_requested':
      return <div className="status status_active">Queried</div>
    case 'generating':
      return <div className="status status_disabled">Generating</div>
    case 'confirming':
      return <div className="status status_default">Confirming</div>
    case 'paid':
      return <div className="status status_success">Paid</div>
    case 'failed':
      return <div className="status status_error">Failed</div>
    case 'late':
      return <div className="status status_error">Overdue</div>
    case 'cancelled':
      return <div className="status status_warning">Cancelled</div>
    case 'canceled':
      return <div className="status status_warning">Cancelled</div>
    case 'terminated':
      return <div className="status status_warning">Terminated</div>
    case 'refunded':
      return <div className="status status_default">Refunded</div>
    case 'payroll_returned_after_cancellation':
      return <div className="status status_active color_text_300">Generating</div>
    case 'in_active':
      return <div className="status status_default">Inactive</div>
    case 'inactive':
      return <div className="status status_default">Inactive</div>
    case 'payroll_completed':
      return <div className="status status_success">Complete</div>
    case 'payroll_need_changes_from_partner':
      return <div className="status status_error">Need change from partner</div>
    case 'rejected':
      return <div className="status status_error">Rejected</div>
    case 'payroll_created':
      return <div className="status status_active color_text_300">Generating</div>
    case 'payroll_approved_by_partner':
      return <div className="status status_active color_text_300">Generating</div>
    case 'payroll_approved_by_admin':
      return <div className="status status_warning">To approve</div>
    case 'payroll_generating_invoice':
      return <div className="status status_default">Generating Invoice</div>
    case 'payroll_approved_by_client':
      return <div className="status status_success">Approved by client</div>
    case 'payroll_invoice_created':
      return <div className="status status_default">Invoice created</div>
    case 'payroll_need_changes_from_admin':
      return <div className="status status_error">Change request sent</div>
    case 'signed':
      return <div className="status status_success">Signed</div>
    case 'not_signed':
      return <div className="status status_default">Pending</div>
    case 'uploaded':
      return <div className="status status_success">Uploaded</div>
    case 'monthly':
      return <div className="status status_success">Monthly</div>
    case 'every_second_month':
      return <div className="status status_success">Every second month</div>
    case 'quarterly':
      return <div className="status status_success">Quarterly</div>
    case 'need_changes_from_admin':
      return <div className="status status_error">Need change from admin</div>
    case 'medical_outstanding':
      return <div className="status status_default">Medical outstanding</div>
    case 'info_outstanding':
      return <div className="status status_default">Info outstanding</div>
    default:
      return <div>{status}</div>
  }
}
