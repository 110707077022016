import './InvoicesPage.scss'

import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Select from '@atoms/Select/Select'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { coin, dismissCircle } from '@core/icons/icons'
import { paymentReports } from '@services/payments.service'
import { fetchPaymentMethods } from '@services/stripe.service'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getAggregatedInvoice,
  getAggregatedInvoiceReport,
  updateAggregatedInvoice,
} from 'src/services/aggregated-invoices.service'
import { useBoolean } from 'usehooks-ts'

import InvoiceDownload from './InvoiceDownload'
import { parseInvoice } from './parser'

export default function InvoicesPage() {
  const { profile } = useContext(AppContext)
  const navigate = useNavigate()
  const [invoices, setInvoices] = React.useState([])
  const [isEmpty, setIsEmpty] = React.useState(null)
  const [page, setPage] = useState(1)
  const [tab, setTab] = React.useState(0)
  const [type, setType] = useState('payments,platform_fee,other,deposit')
  const [employeeType, setEmployeeType] = useState('FTE')
  const { value: visibleCancel, toggle: toggleCancel } = useBoolean(false)
  const visibleNotication = useBoolean(true)
  const [selectedInvoiceCancel, setSelectedInvoiceCancel] = useState(null)
  const [rejectReason, setRejectReason] = useState('')
  const {
    isLoading: isLoadingInvoice,
    isFetching,
    data,
    refetch: refetchInvoice,
  } = useQuery(['aggregatedInvoices', profile, page, type], () =>
    getAggregatedInvoice({
      offset: (page - 1) * 20,
      limit: 20,
      company_id: profile.id,
      invoice_type_in: type,
    })
  )

  const { data: paymentMethods } = useQuery('getPaymentMethods', {
    queryFn: fetchPaymentMethods,
  })
  const paymentMethodsList = paymentMethods?.filter((paymentMethod) => paymentMethod.payment_method?.created)

  const cancelInvoiceRequest = useMutation({
    mutationFn: (payload) => updateAggregatedInvoice(payload),
    onSuccess: () => {
      refetchInvoice()
      toggleCancel()
      toast.success('Successfully updated!')
    },
  })

  const downloadInvoiceFile = useMutation({
    mutationFn: getAggregatedInvoiceReport,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `invoice_${moment(new Date()).format('DD_MM_YYYY')}.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    },
  })

  const downloadCSVRequest = useMutation({
    mutationFn: paymentReports.fetch,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `Contractor_Payments_${moment(new Date()).format('DD_MM_YYYY')}.csv`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    },
  })

  const onDownload = () => {
    downloadCSVRequest.mutate()
  }

  const onTabChange = (id) => {
    setTab(id)
    setPage(1)
    switch (id) {
      case 0:
        setEmployeeType('FTE')
        return setType('payments,platform_fee,other,deposit')
      case 1:
        setEmployeeType('Contractor')
        return setType('aor,contractors_subscription_fee,contractor')
      default:
        return null
    }
  }

  const handleCancel = (event, row) => {
    event.stopPropagation()
    toggleCancel()
    setSelectedInvoiceCancel(row.id)
  }

  const fields = [
    ...(employeeType === 'Contractor'
      ? [
          {
            title: 'ID',
            key: 'nium_payment_request_id',
            type: 'string',
            maxWidth: 180,
            minWidth: 180,
            width: 180,
          },
        ]
      : []),
    {
      title: 'Created on',
      key: 'date',
      type: 'custom',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      render: (item) => (
        <div className="d-flex align-items-center">
          <Typography className="text_regular__14">{item.date}</Typography>
          {item.payment_method === 'ACH' && (
            <Typography className="text_light__12 invoice-status ml-2">{item.payment_method}</Typography>
          )}
        </div>
      ),
    },
    {
      title: 'Amount due',
      key: 'amount',
      type: 'string',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
    },
    {
      title: employeeType === 'FTE' ? 'No. of employees' : 'No. of contractors',
      key: 'invoiceCount',
      maxWidth: 138,
      minWidth: 138,
      width: 138,
    },
    {
      title: 'Type',
      key: 'type',
      type: 'string',
      maxWidth: 160,
      minWidth: 160,
      width: 160,
    },
    {
      title: 'Status',
      key: 'status',
      type: 'status_invoice',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
    },
    {
      title: 'Action',
      key: 'custom',
      type: 'custom',
      maxWidth: 206,
      minWidth: 206,
      width: 206,
      render: (row) => (
        <div className="d-flex align-items-center">
          {row.status !== 'generating' && (
            <div className="d-flex align-items-center mr-2">
              <InvoiceDownload invoice={row} />
            </div>
          )}
          <div>
            {row.status === 'created' && tab !== 0 && (
              <Button
                data-testid="InvoicesPage-E874B1"
                onClick={(event) => handleCancel(event, row)}
                className="text_light__12 px-2 pl-0"
                priority="secondary"
                size="small"
                style={{ height: '32px' }}
              >
                <Icon icon={dismissCircle} className="mr-2" />
                Cancel
              </Button>
            )}
          </div>
        </div>
      ),
    },
  ]

  React.useEffect(() => {
    if (data) {
      setInvoices(data.results)
      setIsEmpty(data.results.length === 0)
    }
  }, [data, page])

  const onChangeReject = (e) => {
    setRejectReason(e.value)
  }
  const onRejectExpense = () => {
    cancelInvoiceRequest.mutate({
      id: selectedInvoiceCancel,
      data: {
        status: 'cancelled',
        reason: rejectReason,
      },
    })
  }

  const onClickDownload = () => {
    downloadInvoiceFile.mutate({
      companies: profile.id,
    })
  }

  useEffect(() => {
    if (profile) refetchInvoice()
  }, [profile, page, type])

  const handleRowClick = (row) => {
    if (!row.invoices.length) return
    navigate(`${row.id}`)
  }

  return (
    <div className="invoices-page h-100">
      <div className="d-flex align-items-center justify-content-between">
        <PageTitle styleClass="mb-4"> Invoices </PageTitle>
        {tab === 0 ? (
          <Button
            data-testid="InvoicesPage-DA97C6"
            className="ml-2"
            priority="secondary"
            size="small"
            loading={downloadInvoiceFile.isLoading}
            onClick={onClickDownload}
          >
            <HiDownload className="mr-1" />
            Download invoice report
          </Button>
        ) : (
          <Button
            data-testid="InvoicesPage-218E7F"
            className="ml-2"
            priority="secondary"
            size="small"
            loading={downloadCSVRequest.isLoading}
            onClick={onDownload}
          >
            <HiDownload className="mr-1" />
            Download contractor invoice report
          </Button>
        )}
      </div>
      {visibleNotication.value && paymentMethodsList?.length > 0 && (
        <NotificationBlock
          styledClass="notification"
          onClose={visibleNotication.setFalse}
          render={
            <Typography className="text_light__12">
              You have set up ACH Direct Debit payment method which can only be used for full-time employee payroll
              payments. Our team are working hard on contractor payment via Direct Debit and will notify you once it is
              ready; meanwhsile please use payment details on invoices to settle contractor payments.
            </Typography>
          }
        />
      )}
      {tab === 0 ? (
        <NotificationBlock
          text="Full-Time Employees Payments"
          cards={[
            {
              title: 'Invoice is generated',
              description:
                'An invoice will be automatically generated once your payroll is approved. You will receive a notification when it’s ready.',
              icon: coin,
            },
            {
              title: 'Process the payment',
              description:
                'To ensure timely payments for your team members, please process the payment for the generated invoice promptly.',
              icon: coin,
            },
          ]}
          isExpanded={isEmpty}
        />
      ) : (
        <NotificationBlock
          text="Contractor Payments"
          cards={[
            {
              title: 'Review Draft Contractor Payments',
              description: (
                <>
                  Go to the{' '}
                  <a
                    data-testid="ach-info-modal.component-757EE6"
                    style={{ textDecoration: 'none', color: '#04C53A' }}
                    href="/pages/pay-contractors"
                  >
                    Pay Contractors{' '}
                  </a>
                  page to review the draft payments that have been automatically generated for your contractors.
                </>
              ),
              icon: coin,
            },
            {
              title: 'Create Invoice and Pay',
              description:
                'After reviewing the draft payments, select the relevant contractor payments and generate your invoice. Complete the payment process to ensure your contractors are paid on time',
              icon: coin,
            },
          ]}
          isExpanded={isEmpty}
        />
      )}
      <Tabs className="mt-4" selectedTab={tab} onTabChange={onTabChange}>
        <Tab tabId={0} title="Full-time employees">
          {!isEmpty && (
            <CustomTable
              fields={fields}
              total={data?.count || 0}
              pageSize={20}
              page={page}
              loading={isLoadingInvoice || isFetching}
              data={parseInvoice(invoices)}
              onPage={setPage}
            />
          )}

          {isEmpty && (
            <div className="d-flex justify-content-center align-items-center flex-grow-1">
              <div className="text-center">
                <img src="/assets/img/ec-empty-shopping-cart.png" alt="" />
                <p className="heading_semibold__20 mb-3 mt-4">No full-time employee invoices yet.</p>
                <p className="text_light__14">They will appear here once generated after you approve payroll.</p>
                <Button
                  data-testid="InvoicesPage-12E748"
                  priority="primary"
                  size="small"
                  className="mt-3"
                  onClick={() => {
                    navigate('/pages/payrolls')
                  }}
                >
                  Go to payroll
                </Button>
              </div>
            </div>
          )}
        </Tab>
        <Tab tabId={1} title="Contractor">
          {!isEmpty && (
            <CustomTable
              fields={fields}
              total={data?.count || 0}
              pageSize={20}
              page={page}
              loading={isLoadingInvoice || isFetching}
              data={parseInvoice(invoices)}
              onPage={setPage}
              onRowClick={handleRowClick}
            />
          )}
          {isEmpty && (
            <div className="d-flex justify-content-center align-items-center flex-grow-1">
              <div className="text-center">
                <img src="/assets/img/ec-empty-shopping-cart.png" alt="" />
                <p className="heading_semibold__20 mb-3 mt-4">No contractor invoices yet</p>
                <p className="text_light__14">Pay contractors and generate your first invoice</p>
                <Button
                  data-testid="InvoicesPage-DC65FF"
                  priority="primary"
                  size="small"
                  className="mt-3"
                  onClick={() => {
                    navigate('/pages/pay-contractors')
                  }}
                >
                  Pay contractors
                </Button>
              </div>
            </div>
          )}
        </Tab>
      </Tabs>

      {visibleCancel && (
        <Confirm
          title="Confirm invoice cancellation"
          onCancel={toggleCancel}
          onYes={onRejectExpense}
          yesButtonProps={{
            text: 'Send',
          }}
        >
          <Typography className="text_light__12 color_text_300">
            You are about to cancel this invoice. Upon confirmation, the invoice status will update to cancelled and
            will not be processed. ️
          </Typography>
          <NotificationBlock
            styledClass="notification"
            render={
              <Typography className="text_light__12">All associated sub-invoices will also be cancelled.</Typography>
            }
          />
          <div>
            <Select
              data-testid="InvoicesPage-DAE1F4"
              placeholder="Select reason"
              isClearable
              label="Please select a reason for cancellation:"
              value={rejectReason || undefined}
              onChange={onChangeReject}
              options={[
                'Invoice has already been paid',
                'Invoice has incorrect information',
                'There is a dispute about this invoice',
              ].map((state) => {
                return {
                  value: state,
                  label: state,
                }
              })}
            />
          </div>
        </Confirm>
      )}
    </div>
  )
}
