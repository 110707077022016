import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { PAYMENT_FREQUENCY } from '@core/constants'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import { getContractsAvailableCurrency } from '@services/contract.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { v4 } from 'uuid'

const HintText = styled.span`
  font-size: 12px;
  color: #8a96a1;
  margin-top: 4px;
`

export default ({ initValue, loading, onFinish, workingCountryId, isEdit, setHasMilestones }) => {
  const {
    control,
    register,
    watch,
    reset,
    resetField,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contractTerm: 'indefinite',
      contractor_rate: '',
      currency: '',
      estimated_working_hours: '',
      contractor_payment_frequency: '',
    },
  })
  const { countries, currencies, profile } = useApp()

  const { data, isFetching } = useQuery({
    queryKey: ['availableCurrencies', workingCountryId],
    queryFn: () => getContractsAvailableCurrency(workingCountryId),
    enabled: !!workingCountryId,
  })
  const [isMilestone, setIsMilestone] = useState(false)
  const country = countries.find((item) => item.id === workingCountryId)
  const contractor_wage_type_watch = watch('contractor_wage_type')
  const frequency = watch('contractor_payment_frequency')
  const isFixed = watch('contractTerm') === 'fixed'

  useEffect(() => {
    if (isEdit && !loading) {
      reset({
        contractTerm: initValue?.job?.is_permanent ? 'indefinite' : 'fixed',
        contractor_rate: initValue.compensation?.contractor_rate,
        estimated_working_hours: initValue.compensation?.estimated_working_hours,
        contractor_wage_type: initValue.compensation?.contractor_wage_type,
        contractor_payment_frequency: initValue.compensation?.contractor_payment_frequency,
        currency: initValue.compensation?.currency?.id,
        receiving_currency: initValue.compensation?.receiving_currency?.id,
        requested_starts_at: new Date(initValue.job?.requested_starts_at),
        starts_at: new Date(initValue.job?.requested_starts_at),
        ends_at: initValue.job?.ends_at ? new Date(initValue.job?.ends_at) : null,
      })
    }
  }, [initValue, isEdit, loading])

  useEffect(() => {
    resetField('currency')
  }, [workingCountryId])

  useEffect(() => {
    const hasMilestone = contractor_wage_type_watch === 'milestone'
    setIsMilestone(hasMilestone)
    if (!hasMilestone) {
      setValue('contractor_payment_frequency', null)
    }
  }, [contractor_wage_type_watch])

  const getContractorReceivingCurrencies = () => {
    if (country?.nium_supported && data?.available_currencies.length) {
      return data.available_currencies.map((c) => ({
        label: `${c.short_code}${c.is_local ? ' (local currency)' : ''}`,
        value: c.id,
      }))
    }
    const defaultCurrencyShortCode = 'USD'
    const localCurrency = country?.currency
    const usdCurrency = currencies.find((item) => item.short_code === defaultCurrencyShortCode)

    return usdCurrency?.id !== localCurrency?.id
      ? [
          {
            label: defaultCurrencyShortCode,
            value: usdCurrency?.id,
          },
          {
            label: `${localCurrency?.short_code || ''} (local currency)`,
            value: localCurrency?.id,
          },
        ]
      : [
          {
            label: defaultCurrencyShortCode,
            value: usdCurrency?.id,
          },
        ]
  }

  useEffect(() => {
    if (isEdit && !loading) {
      reset({
        contractTerm: initValue?.job?.is_permanent ? 'indefinite' : 'fixed',
        contractor_rate: initValue.compensation?.contractor_rate,
        estimated_working_hours: initValue.compensation?.estimated_working_hours,
        contractor_wage_type: initValue.compensation?.contractor_wage_type,
        contractor_payment_frequency: initValue.compensation?.contractor_payment_frequency,
        currency: {
          value: initValue.compensation?.currency?.id,
          label: `${initValue.compensation?.currency?.name} (${initValue.compensation?.currency?.short_code})`,
        },
        receiving_currency: {
          value: initValue.compensation?.receiving_currency?.id,
          label: initValue.compensation?.receiving_currency?.name,
        },
        requested_starts_at: new Date(initValue.job?.requested_starts_at),
        starts_at: new Date(initValue.job?.requested_starts_at),
        ends_at: initValue.job?.ends_at ? new Date(initValue.job?.ends_at) : null,
      })
    }
  }, [initValue, isEdit, loading])

  useEffect(() => {
    resetField('currency')
  }, [workingCountryId])

  useEffect(() => {
    const hasMilestone = contractor_wage_type_watch === 'milestone'
    setIsMilestone(hasMilestone)
    if (!hasMilestone) {
      setValue('contractor_payment_frequency', null)
    }
  }, [contractor_wage_type_watch])

  const submit = (formValues) => {
    const payload = {
      compensation: {
        currency: +formValues.currency.value,
        receiving_currency: +formValues.receiving_currency.value,
        contractor_wage_type: formValues.contractor_wage_type,
        estimated_working_hours: Number(formValues.estimated_working_hours),
        ...(!isMilestone && {
          contractor_rate: Number(formValues.contractor_rate),
        }),
        ...(!isMilestone && {
          contractor_payment_frequency: formValues.contractor_payment_frequency,
        }),
      },
      job: {
        ...initValue.job,
        requested_starts_at: moment(formValues.requested_starts_at).format('YYYY-MM-DD'),
        starts_at: moment(formValues.requested_starts_at).format('YYYY-MM-DD'),
        ends_at: isFixed ? moment(formValues.ends_at).format('YYYY-MM-DD') : null,
        is_permanent: !isFixed,
        occupation: initValue.job?.occupation?.id,
      },
    }

    if (contractor_wage_type_watch !== 'hourly') {
      delete payload.compensation.estimated_working_hours
    }

    if (isMilestone) {
      delete payload.compensation.contractor_rate
      delete payload.compensation.contractor_payment_frequency
    }

    onFinish(payload)
  }

  const handleWageTypeChange = (event) => {
    if (event.target.value === 'milestone') {
      setHasMilestones(true)
    } else {
      setHasMilestones(false)
    }
  }

  if (isFetching) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100 employees-page__form">
      <section className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="contractTerm"
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Contract term"
                options={[
                  {
                    id: 'indefinite',
                    text: 'Indefinite',
                    value: 'indefinite',
                  },
                  {
                    id: 'fixed',
                    text: 'Fixed term',
                    value: 'fixed',
                  },
                ]}
              />
            )}
          />
        </div>
        <div className="d-flex gap-3 w-100 remo-form-input">
          <div className="remo-form-input">
            <Controller
              control={control}
              name="requested_starts_at"
              rules={{ required: 'Start date is required' }}
              render={({ field }) => {
                return (
                  <DateInput
                    label="Start date"
                    disabled={field.disabled}
                    value={field.value}
                    onChange={(date) => {
                      field.onChange(date)
                    }}
                  />
                )
              }}
            />
            {errors.requested_starts_at && (
              <Typography className="text_regular__14 color_red">{errors.requested_starts_at.message}</Typography>
            )}
          </div>
          {isFixed && (
            <div className="remo-form-input">
              <Controller
                control={control}
                rules={{ required: 'End date is required' }}
                name="ends_at"
                render={({ field }) => {
                  return (
                    <DateInput
                      label="End date"
                      disabled={field.disabled}
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                    />
                  )
                }}
              />
              {errors.ends_at && (
                <Typography className="text_regular__14 color_red">{errors.ends_at.message}</Typography>
              )}
            </div>
          )}
        </div>
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="contractor_wage_type"
            rules={{ required: 'Wage type is required' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Rate type"
                options={[
                  {
                    id: 'monthly2',
                    text: 'Monthly',
                    value: 'monthly',
                  },
                  {
                    id: 'daily',
                    text: 'Daily',
                    value: 'daily',
                  },
                  {
                    id: 'hourly',
                    text: 'Hourly',
                    value: 'hourly',
                  },
                  {
                    id: 'milestone',
                    text: 'Milestone',
                    value: 'milestone',
                  },
                ]}
                onChange={(event) => {
                  field.onChange(event)
                  if (setHasMilestones) {
                    handleWageTypeChange(event)
                  }
                }}
              />
            )}
          />
          {errors.contractor_wage_type && (
            <Typography className="text_regular__14 color_red">{errors.contractor_wage_type.message}</Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="currency"
            rules={{ required: 'Contract currency is required.' }}
            render={({ field }) => {
              return (
                <>
                  <Select
                    {...field}
                    isRequired
                    label="Select contract currency"
                    placeholder="Select"
                    options={
                      currencies.map((item) => ({
                        value: item.id,
                        label: `${item.name} (${item.short_code})`,
                      })) || []
                    }
                  />
                  <HintText>
                    {`This is the currency specified in the contract. You will be invoiced in your company's default
                    currency (${profile.default_currency?.short_code}), while the contractor will receive payment in their preferred currency.`}
                  </HintText>
                </>
              )
            }}
          />
          {errors.currency && <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>}
        </div>

        {!isMilestone && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="contractor_rate"
              rules={{ required: 'Contractor rate is required' }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  step=".01"
                  label="Contractor rate"
                  placeholder="00.00"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />

            {errors.contractor_rate && (
              <Typography className="text_regular__14 color_red">{errors.contractor_rate.message}</Typography>
            )}
          </div>
        )}

        <div className="remo-form-input">
          <Controller
            control={control}
            name="receiving_currency"
            rules={{ required: 'Contractor receiving currency is required.' }}
            render={({ field }) => {
              return (
                <>
                  <Select
                    {...field}
                    isRequired
                    label="Select contractor receiving currency"
                    placeholder="Select"
                    options={getContractorReceivingCurrencies()}
                  />
                  <HintText>
                    {`You will be invoiced in your company's default currency (${profile.default_currency?.short_code}), while the contractor will receive payment in their preferred currency.`}
                  </HintText>
                </>
              )
            }}
          />
          {errors.receiving_currency && (
            <Typography className="text_regular__14 color_red">{errors.receiving_currency.message}</Typography>
          )}
        </div>
        {!isMilestone && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="contractor_payment_frequency"
              rules={{ required: 'Payment frequency is required' }}
              render={({ field }) => {
                return (
                  <RadioButton
                    {...field}
                    label={
                      <div className="d-flex gap-2">
                        Payment frequency
                        <Tooltip
                          id={v4()}
                          clickable
                          style={{ width: '500px' }}
                          content={
                            <div>
                              Payment periods are automatically calculated based on the selection in this column:
                              <ul>
                                <li>Weekly - payments will be automatically created each week;</li>
                                <li>Semi-monthly - payments will be automatically created twice a month;</li>
                                <li>Monthly - payments will be automatically created once a month;</li>
                                <li>None - payments will not be automatically created;</li>
                              </ul>
                            </div>
                          }
                        />
                      </div>
                    }
                    options={
                      PAYMENT_FREQUENCY.map((item) => ({ id: item.value, text: item.label, value: item.value })) || []
                    }
                  />
                )
              }}
            />
            {errors.contractor_payment_frequency && (
              <Typography className="text_regular__14 color_red">
                {errors.contractor_payment_frequency.message}
              </Typography>
            )}
          </div>
        )}

        {contractor_wage_type_watch === 'hourly' && (
          <div className="w-100 remo-form-input">
            <Input
              data-testid="CompensationForm-D7B026"
              id={`input_${frequency?.value}`}
              {...register('estimated_working_hours', {
                required: 'Number of hours is required',
              })}
              type="number"
              label={`Number of hours ${
                frequency
                  ? `(estimated ${PAYMENT_FREQUENCY.find((f) => f.value === frequency)?.label.toLowerCase()})`
                  : ''
              }`}
              placeholder="Estimated working hours"
              tooltip={
                <Tooltip
                  id={v4()}
                  clickable
                  style={{ width: '500px' }}
                  content="Enter the estimated number of hours the contractor will work over a chosen period"
                />
              }
            />
            {errors.estimated_working_hours && (
              <Typography className="text_regular__14 color_red">{errors.estimated_working_hours.message}</Typography>
            )}
          </div>
        )}
      </section>

      <div className="pb-5 align-self-center">
        <Button
          data-testid="CompensationForm-1B8E63"
          type="submit"
          className="align-self-end"
          loading={loading}
          disabled={loading}
        >
          {isEdit || isMilestone ? 'Save and continue' : 'Add contractor'}
        </Button>
      </div>
    </form>
  )
}
