import Avatar from '@atoms/Avatar/Avatar'
import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import EditAvatar from '@atoms/EditAvatar/EditAvatar'
import EditModal from '@atoms/EditModal/EditModal'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { UpdateContractModal } from '@components/contract-update-modal'
import { CONTRACT_FIELDS, CONTRACT_TYPE, RESIDENCY_TYPES, SUB_STATE_CHANGE_REQUEST_TYPES } from '@core/constants'
import { AppContext } from '@core/context'
import { getPersonalInformation } from '@pages/employeeDetailPage/mock'
import { patchContractById } from '@store/contracts'
import moment from 'moment'
import { useCallback, useContext, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { ContractUpdateNotification } from '../../../../components/contract-update-notification'
import {
  createSubStateChangeRequest,
  deactivateContract,
  mutationUploadAvatar,
  reactivateContract,
} from '../../../../services/contract.service'
import { CancelOnboardingModal } from './CancelOnboardingModal'
import { DeactivateButton } from './DeactivateButton'

export default function Personal({ employee, refetch, setEmployee, openAgreementUpdateConfirm }) {
  const { countries } = useContext(AppContext)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [visibleDeactivate, setVisibleDeactivate] = useState(false)
  const activateContractorModal = useBoolean(false)
  const [visibleCancelOnboarding, setVisibleCancelOnboarding] = useState(false)

  const initialState = {
    first_name: employee.profile.first_name,
    middle_name: employee.profile.middle_name,
    last_name: employee.profile.last_name,
    email: employee.profile.email,
    avatar: employee.profile.avatar,
    phone_number: employee.profile.phone_number,
    birth_date: employee.profile.birth_date ? employee.profile.birth_date : null,
    living_country: employee.profile.address?.living_country?.id || '',
    street_address: employee.profile.address?.street_address,
    state: employee.profile.address?.state,
    city: employee.profile.address?.city,
    postal_code: employee.profile.address?.postal_code,
    address_line: employee.profile?.address?.address_line,
    home_living_country: employee.profile.home_address?.living_country?.id || '',
    home_street_address: employee.profile.home_address?.street_address,
    home_state: employee.profile.home_address?.state,
    home_city: employee.profile.home_address?.city,
    home_postal_code: employee.profile.home_address?.postal_code,
    home_address_line: employee.profile?.home_address?.address_line,
    needs_work_permit: `${employee.profile?.needs_work_permit}`,
    residency_type: employee.residency_type,
  }

  const [editState, setEditState] = useState(initialState)

  const haveAgreementFieldsUpdated = () => {
    return CONTRACT_FIELDS.filter((field) => {
      return initialState[field] !== editState[field]
    })
  }

  const updatePersonalInfo = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: (res) => {
      setEmployee(res.data)
      setVisibleEdit(false)
      toast.success('Successfully updated')
      const agreementFieldsList = haveAgreementFieldsUpdated()
      if (agreementFieldsList.length) {
        openAgreementUpdateConfirm.setTrue()
      }
    },
  })

  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }
  const onChangeBirthDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      birth_date: value,
    }))
  }
  const onChangeWorkPermit = (e) => {
    setEditState((prevState) => ({
      ...prevState,
      needs_work_permit: e.target.value,
    }))
  }
  const onChangeResidencyType = (e) => {
    setEditState((prevState) => ({
      ...prevState,
      residency_type: e.target.value,
    }))
  }
  const onChangeLivingCountry = (option) => {
    setEditState((prevState) => ({
      ...prevState,
      living_country: option.value,
    }))
  }
  const onChangeHomeLivingCountry = (option) => {
    setEditState((prevState) => ({
      ...prevState,
      home_living_country: option.value,
    }))
  }

  const deactivateEmployee = useMutation({
    mutationFn: (id) => deactivateContract(id),
    onSuccess: () => {
      setVisibleDeactivate(false)
      refetch()
    },
  })

  const activateContract = useMutation({
    mutationFn: ({ id, payload }) => reactivateContract(id, payload),
    onSuccess: () => {
      activateContractorModal.setFalse()
      refetch()
    },
  })

  const onClickActivateContractor = async () => {
    activateContract.mutateAsync({
      id: employee.id,
      payload: {
        state: 'active',
      },
    })
  }

  const createSubStateChangeRequestMutation = useMutation({
    mutationFn: ({ id, payload }) => createSubStateChangeRequest(id, payload),
    onSuccess: () => {
      toast.success('Thank you. Please wait for our team to contact you about the next steps.', {
        position: 'top-right',
        theme: 'colored',
        autoClose: false,
      })
      setVisibleCancelOnboarding(false)
      refetch()
    },
  })

  const uploadProfileAvatar = useMutation({
    mutationFn: (payload) => mutationUploadAvatar(payload.id, payload.file),
    onSuccess: ({ data }) => {
      setEmployee(data)
      setEditState((prevState) => ({
        ...prevState,
        avatar: data.profile.avatar,
      }))
      toast.success('Profile image has been updated successfully')
    },
  })

  const onAvatarUpload = (file) => {
    uploadProfileAvatar.mutate({ id: employee.id, file })
  }

  const onSaveEdit = () => {
    const compensation =
      employee.contract_type === 'contractor'
        ? {
            contractor_rate: employee.compensation.contractor_rate,
            estimated_working_hours: employee.compensation.estimated_working_hours || undefined,
            contractor_wage_type: employee.compensation.contractor_wage_type,
            contractor_payment_frequency: employee.compensation.contractor_payment_frequency,
          }
        : {
            yearly_gross_salary: employee.compensation.yearly_gross_salary,
          }

    const residencyType =
      employee.contract_type !== 'contractor'
        ? {
            residency_type: editState.residency_type,
          }
        : {}
    const data = {
      profile: {
        first_name: editState.first_name,
        middle_name: editState.middle_name,
        last_name: editState.last_name,
        phone_number: editState.phone_number,
        birth_date: editState.birth_date ? moment(editState.birth_date).format('YYYY-MM-DD') : null,
        email: editState.email,
        address: {
          living_country: editState.living_country,
          street_address: editState.street_address,
          city: editState.city,
          postal_code: editState.postal_code,
          state: editState.state,
          address_line: editState.address_line,
        },
        home_address: {
          living_country: editState.home_living_country,
          street_address: editState.home_street_address,
          city: editState.home_city,
          postal_code: editState.home_postal_code,
          state: editState.home_state,
          address_line: editState.home_address_line,
        },
        ...(employee.contract_type === CONTRACT_TYPE.FULL_TIME_EMPLOYEE && {
          needs_work_permit: editState.needs_work_permit,
        }),
      },
      compensation,
      ...residencyType,
    }
    updatePersonalInfo.mutate({ id: employee.id, data })
  }

  const onClickDeactivate = () => {
    deactivateEmployee.mutate(employee.id)
  }

  const handleCancelOnboarding = ({ reason, description }) => {
    createSubStateChangeRequestMutation.mutate({
      id: employee.id,
      payload: {
        reason,
        request_type: SUB_STATE_CHANGE_REQUEST_TYPES.CANCELLED,
        comment: description,
      },
    })
  }

  const handleDeactivateOpen = useCallback(() => setVisibleDeactivate(true), [])
  const handleCancelOnboardingOpen = useCallback(() => setVisibleCancelOnboarding(true), [])
  const handleDeactivateClose = useCallback(() => setVisibleDeactivate(false), [])
  const handleCancelOnboardingClose = useCallback(() => setVisibleCancelOnboarding(false), [])

  const handleCloseEditModal = () => {
    setEditState(initialState)
    setVisibleEdit(false)
  }
  const showUpdateContractModal = useBoolean(false)

  return (
    <div style={{ maxWidth: 540 }}>
      <div className="d-flex flex-column">
        {/* <div className="mb-3">
          <ContractUpdateNotification
            updateSource="Jon Jones"
            variant="normal"
            onClick={() => showUpdateContractModal.setTrue()}
          />
        </div> */}

        <div className="d-flex align-items-center justify-content-between mb-4">
          <Typography className="heading_semibold__20 color_black">Personal information</Typography>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <Button
              data-testid="Personal-7A0795"
              priority="secondary"
              size="small"
              onClick={() => setVisibleEdit(true)}
            >
              Edit
            </Button>
            <DeactivateButton
              contractType={employee.contract_type}
              state={employee.state}
              subState={employee.sub_state}
              onDeactivate={handleDeactivateOpen}
              onOnboardingCancel={handleCancelOnboardingOpen}
              onActivate={activateContractorModal.setTrue}
            />
          </div>
        </div>
        <Avatar src={editState.avatar} width={100} height={100} className="mt-2 mb-5" />
        <List
          lists={getPersonalInformation(employee).map((v) => ({
            ...v,
            value: v.value?.join(', '),
          }))}
        />
      </div>
      <EditModal
        visible={visibleDeactivate}
        title={employee.contract_type === CONTRACT_TYPE.CONTRACTOR ? 'Deactivate contract' : 'Request Offboarding'}
        onSave={onSaveEdit}
        footer={false}
        closeModal={() => setVisibleDeactivate(false)}
      >
        <Typography className="heading_semi__16">
          {employee.contract_type === CONTRACT_TYPE.CONTRACTOR
            ? 'Are you sure you want to deactivate this contractor? Any existing draft payment will be removed for this contractor.'
            : 'Are you sure you want to begin offboarding this employee from your company?'}
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="Personal-87BE54"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={() => setVisibleDeactivate(false)}
          >
            Cancel
          </Button>
          <Button
            data-testid="Personal-E1864B"
            loading={deactivateEmployee.isLoading}
            disabled={deactivateEmployee.isLoading}
            priority="primary_dangerous"
            size="small"
            onClick={onClickDeactivate}
          >
            {employee.contract_type === CONTRACT_TYPE.CONTRACTOR ? 'Deactivate' : 'Request Offboarding'}
          </Button>
        </div>
      </EditModal>
      <CancelOnboardingModal
        visible={visibleCancelOnboarding}
        loading={createSubStateChangeRequestMutation.isLoading}
        onClose={handleCancelOnboardingClose}
        onSubmit={handleCancelOnboarding}
      />
      <EditModal
        visible={activateContractorModal.value}
        title="Activate contract"
        footer={false}
        closeModal={activateContractorModal.setFalse}
      >
        <Typography className="heading_semi__16">Are you sure you want to activate this contractor?</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="Personal-87EE54"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={activateContractorModal.setFalse}
          >
            Cancel
          </Button>
          <Button
            data-testid="Personal-E18R4B"
            loading={activateContract.isLoading}
            disabled={activateContract.isLoading}
            priority="primary"
            size="small"
            onClick={onClickActivateContractor}
          >
            Activate
          </Button>
        </div>
      </EditModal>
      <EditModal
        visible={visibleEdit}
        title="Personal information"
        onSave={onSaveEdit}
        loading={updatePersonalInfo.isLoading}
        closeModal={handleCloseEditModal}
      >
        <EditAvatar
          src={editState.avatar}
          isLoading={uploadProfileAvatar.isLoading}
          className="mt-1 mb-3"
          onUpload={onAvatarUpload}
        />
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-FFCC37"
            label="Name"
            placeholder="Name"
            name="first_name"
            value={editState?.first_name}
            onChange={onChangeEdit}
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-713D04"
            onChange={onChangeEdit}
            label="Middle name (if applicable)"
            placeholder="Middle name"
            name="middle_name"
            value={editState?.middle_name}
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-7F7405"
            onChange={onChangeEdit}
            label="Surname"
            placeholder="Surname"
            name="last_name"
            value={editState?.last_name}
          />
        </div>
        <div className="w-100 remo-form-input">
          <DateInput
            className="mb-3"
            maxDate={new Date()}
            onChange={onChangeBirthDate}
            label="Date of birth"
            value={editState?.birth_date}
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="Personal-8023E6"
            onChange={onChangeEdit}
            label="Phone number"
            placeholder="Phone number"
            name="phone_number"
            type="text"
            value={editState?.phone_number}
          />
        </div>
        {employee.contract_type === CONTRACT_TYPE.FULL_TIME_EMPLOYEE && (
          <>
            <div className="w-100 remo-form-input">
              <RadioButton
                label="Work eligibility"
                onChange={onChangeWorkPermit}
                value={editState.needs_work_permit}
                addText="Does this employee require a work eligibility document (work permit, visa, etc)?"
                options={[
                  {
                    id: 'needs_work_permit_true',
                    value: 'true',
                    text: 'Yes',
                  },
                  {
                    id: 'needs_work_permit_false',
                    value: 'false',
                    text: 'No',
                  },
                ]}
              />
            </div>

            <div className="w-100 remo-form-input">
              <RadioButton
                label="Residency type"
                onChange={onChangeResidencyType}
                value={editState.residency_type}
                addText="Is this employee a Resident or Non-Resident?"
                options={[
                  {
                    id: 'resident',
                    value: RESIDENCY_TYPES.RESIDENT,
                    text: 'Resident',
                  },
                  {
                    id: 'non-resident',
                    value: RESIDENCY_TYPES.NON_RESIDENT,
                    text: 'Non-Resident',
                  },
                ]}
              />
            </div>
          </>
        )}
        <>
          <Typography className="heading_semibold__20 color_black mt-4 mb-3">
            {employee.contract_type === 'contractor' ? 'Billing address' : 'Home address'}
          </Typography>
          {countries && (
            <div className="w-100 remo-form-input">
              <Select
                data-testid="Personal-9FB35D"
                options={countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
                name="living_country"
                id="living_country"
                placeholder="e.g United Kingdom"
                onChange={onChangeLivingCountry}
                value={editState?.living_country}
                label="Country"
              />
            </div>
          )}

          <div className="w-100 remo-form-input">
            <Input
              data-testid="Personal-83416E"
              onChange={onChangeEdit}
              label="State"
              placeholder="State"
              name="state"
              value={editState?.state}
            />
          </div>
          <div className="w-100 remo-form-input">
            <Input
              data-testid="Personal-50015D"
              onChange={onChangeEdit}
              label="City"
              name="city"
              value={editState?.city}
            />
          </div>
          <div className="w-100 remo-form-input">
            <Input
              data-testid="Personal-B9335D"
              onChange={onChangeEdit}
              label="Address"
              placeholder="Address"
              name="street_address"
              value={editState?.street_address}
            />
          </div>
          <div className="w-100 remo-form-input">
            <Input
              data-testid="Personal-CC6D51"
              onChange={onChangeEdit}
              label="Postal code"
              placeholder="Postal code"
              name="postal_code"
              value={editState?.postal_code}
            />
          </div>
          <div className="w-100 remo-form-input">
            <Input
              data-testid="Personal-0BC568"
              onChange={onChangeEdit}
              label="Address line"
              placeholder="Address line"
              name="address_line"
              value={editState?.address_line}
            />
          </div>
        </>

        {employee.contract_type === 'contractor' && (
          <>
            <Typography className="heading_semibold__20 color_black mt-4 mb-3">Home address</Typography>
            {countries && (
              <div className="w-100 remo-form-input">
                <Select
                  data-testid="Personal-81E236"
                  options={countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                  name="home_living_country"
                  id="living_country"
                  placeholder="e.g United Kingdom"
                  onChange={onChangeHomeLivingCountry}
                  value={editState?.home_living_country}
                  label="Country"
                />
              </div>
            )}

            <div className="w-100 remo-form-input">
              <Input
                data-testid="Personal-4D47DA"
                onChange={onChangeEdit}
                label="State"
                placeholder="State"
                name="home_state"
                value={editState?.home_state}
              />
            </div>
            <div className="w-100 remo-form-input">
              <Input
                data-testid="Personal-79165C"
                onChange={onChangeEdit}
                label="City"
                name="home_city"
                value={editState?.home_city}
              />
            </div>
            <div className="w-100 remo-form-input">
              <Input
                data-testid="Personal-818172"
                onChange={onChangeEdit}
                label="Address"
                placeholder="Address"
                name="home_street_address"
                value={editState?.home_street_address}
              />
            </div>
            <div className="w-100 remo-form-input">
              <Input
                data-testid="Personal-54A8B7"
                onChange={onChangeEdit}
                label="Postal code"
                placeholder="Postal code"
                name="home_postal_code"
                value={editState?.home_postal_code}
              />
            </div>
            <div className="w-100 remo-form-input">
              <Input
                data-testid="Personal-80DA45"
                onChange={onChangeEdit}
                label="Address line"
                placeholder="Address line"
                name="home_address_line"
                value={editState?.home_address_line}
              />
            </div>
          </>
        )}
      </EditModal>
      <UpdateContractModal
        employee={employee}
        showUpdateContractModal={showUpdateContractModal}
        withSelectCancelButton
      />
    </div>
  )
}
