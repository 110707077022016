import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import DateInput from '@atoms/DateInput/DateInput'
import EditModal from '@atoms/EditModal/EditModal'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { Dropdown } from '@components/dropdown/dropdown.component'
import { CONTRACT_FIELDS, PAYMENT_FREQUENCY } from '@core/constants'
import { useApp } from '@core/context'
import { useDirectManagers } from '@core/hooks/useDirectManagers'
import { setCurrencyChangeEvent } from '@core/utils'
import { getCompensationInfo, getJobDetails } from '@pages/employeeDetailPage/mock'
import { getContractsAvailableCurrency, getJobDetailsList } from '@services/contract.service'
import { patchContractById } from '@store/contracts'
import moment from 'moment'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { SalaryChangeDetails } from './SalaryChangeDetails'

export default function JobDetails({ employee, setEmployee, occupations, openAgreementUpdateConfirm }) {
  const { countries, profile, currencies } = useApp()
  const country = countries.find((item) => item.id === employee?.working_country.id)
  const dropdownRef = useRef(null)
  const dropdownOpenState = useBoolean(false)
  const [salaryChange] = employee.salary_changes
  const [visibleEdit, setVisibleEdit] = useState(false)

  const initialState = {
    ends_at: employee.job.ends_at ? employee.job.ends_at : null,
    requested_starts_at: employee.job?.requested_starts_at ? employee.job?.requested_starts_at : null,
    is_permanent: employee.job.is_permanent,
    position: employee.job.position,
    position_description: employee.job.position_description,
    currency: employee.compensation.currency?.id,
    receiving_currency: employee.compensation.receiving_currency?.id,
    contractor_payment_frequency: employee.compensation.contractor_payment_frequency,
    yearly_gross_salary: employee.compensation.yearly_gross_salary,
    contractor_rate: employee.compensation.contractor_rate,
    estimated_working_hours: employee.compensation.estimated_working_hours,
    contractor_wage_type: employee.compensation.contractor_wage_type,
    department: employee.department,
    living_country: employee?.profile.address?.living_country?.id || null,
    working_country: employee?.working_country.id || null,
    direct_manager: employee?.direct_manager?.id || null,
    citizenship_country: employee?.profile.citizenship_country?.id || null,
    occupation: employee?.job?.occupation?.id,
  }

  const [editState, setEditState] = useState(initialState)
  const hasOccupations = useMemo(() => !!occupations && occupations.length > 0, [occupations])
  const { directManagers, isLoading } = useDirectManagers(profile.id)

  const jobDetailsListQuery = useQuery({
    queryKey: [getJobDetailsList.key],
    queryFn: getJobDetailsList.fetch,
  })

  const haveAgreementFieldsUpdated = () => {
    return CONTRACT_FIELDS.filter((field) => {
      return initialState[field] !== editState[field]
    })
  }

  const { data: receivingCurrencies, isLoading: isReceivingCurrenciesLoading } = useQuery({
    queryKey: ['availableCurrencies', employee?.working_country.id],
    queryFn: () => getContractsAvailableCurrency(employee?.working_country.id),
    enabled: !!employee?.working_country.id,
  })

  const getContractorReceivingCurrencies = () => {
    if (country?.nium_supported && receivingCurrencies?.available_currencies.length) {
      return receivingCurrencies.available_currencies.map((c) => ({
        label: `${c.short_code}${c.is_local ? ' (local currency)' : ''}`,
        value: c.id,
      }))
    }
    const defaultCurrencyShortCode = 'USD'
    const localCurrency = country?.currency
    const usdCurrency = currencies.find((item) => item.short_code === defaultCurrencyShortCode)

    return usdCurrency?.id !== localCurrency?.id
      ? [
          {
            label: defaultCurrencyShortCode,
            value: usdCurrency?.id,
          },
          {
            label: `${localCurrency?.short_code || ''} (local currency)`,
            value: localCurrency?.id,
          },
        ]
      : [
          {
            label: defaultCurrencyShortCode,
            value: usdCurrency?.id,
          },
        ]
  }

  const putJobDetail = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: (res) => {
      setEmployee(res.data)
      setVisibleEdit(false)
      toast.success('Successfully updated')
      const agreementFieldsList = haveAgreementFieldsUpdated()
      if (agreementFieldsList.length) {
        openAgreementUpdateConfirm.setTrue()
      }
    },
  })

  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }

  const onChangeOccupation = ({ value }) => {
    setEditState((prevState) => ({
      ...prevState,
      occupation: value,
    }))
  }

  const onChangeWage = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      contractor_wage_type: opt.value,
    }))
  }

  const handleCurrencyChange = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      currency: opt.value,
    }))
  }

  const handleReceivingCurrencyChange = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      receiving_currency: opt.value,
    }))
  }

  const onChangeCountry = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      living_country: opt.value,
    }))
  }

  const onChangePosition = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      position: opt.value,
      position_description: opt.scope_of_work,
    }))
  }

  const onChangeManager = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      direct_manager: opt?.value || '',
    }))
  }

  const onPaymentFrequency = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      contractor_payment_frequency: opt.value,
    }))
  }
  const onChangeStartDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      requested_starts_at: value,
    }))
  }

  const onChangeEndDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      ends_at: value,
    }))
  }
  const onRemove = useCallback(() => {
    setEditState((prevState) => ({
      ...prevState,
      ends_at: null,
    }))
  }, [])

  const onSaveEdit = () => {
    if (!editState?.requested_starts_at) return
    const compensation =
      employee.contract_type === 'contractor'
        ? {
            ...employee.compensation,
            contractor_rate: editState.contractor_rate || 0,
            estimated_working_hours: editState.estimated_working_hours || 0,
            contractor_payment_frequency: editState.contractor_payment_frequency,
            contractor_wage_type: editState.contractor_wage_type,
            receiving_currency: editState?.receiving_currency,
            currency: editState.currency,
          }
        : {
            currency: editState.currency,
            yearly_gross_salary: editState.yearly_gross_salary,
          }
    const data = {
      profile: {
        ...employee.profile,
        address: {
          ...employee.profile.address,
          living_country: editState.living_country,
        },
        citizenship_country: editState.citizenship_country,
        home_address: {
          ...employee.profile.home_address,
          living_country: employee.profile.home_address?.living_country?.id || null,
        },
      },
      job: {
        is_permanent: editState.is_permanent,
        ends_at: editState.ends_at && !editState.is_permanent ? moment(editState.ends_at).format('YYYY-MM-DD') : null,
        requested_starts_at: moment(editState.requested_starts_at).format('YYYY-MM-DD'),
        position: editState.position,
        position_description: editState.position_description,
      },
      department: editState.department,
      compensation,
      direct_manager: editState.direct_manager,
    }

    if (hasOccupations && editState.occupation) {
      data.job.occupation = editState.occupation
    }

    if (editState.contractor_wage_type === 'monthly') {
      delete data.compensation.estimated_working_hours
    }

    if (editState.contractor_wage_type === 'milestone') {
      data.compensation.estimated_working_hours = 0
      data.compensation.contractor_payment_frequency = 'one_time_payment'
      data.compensation.contractor_rate = 0
    }

    putJobDetail.mutate({ id: employee.id, data })
  }

  const handleCheckChange = (event) => {
    const { name, checked } = event.target
    setEditState((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handleCloseEditModal = () => {
    setEditState(initialState)
    setVisibleEdit(false)
  }

  return (
    <div style={{ maxWidth: 540 }}>
      <div className="d-flex flex-column mb-3">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Typography className="heading_semibold__20 color_black">Job details</Typography>
          <Button
            data-testid="JobDetails-08D024"
            priority="secondary"
            size="small"
            onClick={() => setVisibleEdit(true)}
          >
            Edit
          </Button>
        </div>
        <List
          lists={[
            ...(hasOccupations ? [{ value: employee?.job?.occupation?.name || '', label: 'Occupation type' }] : []),
            ...getJobDetails(employee),
          ]}
        />
        {salaryChange && <SalaryChangeDetails salaryChange={salaryChange} />}
      </div>
      {employee.contract_type === 'contractor' && (
        <div className="d-flex flex-column mb-3">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <Typography className="heading_semibold__20 color_black">Compensation</Typography>
          </div>
          <List lists={getCompensationInfo(employee)} />
        </div>
      )}
      <EditModal
        visible={visibleEdit}
        title="Job details"
        onSave={onSaveEdit}
        loading={putJobDetail.isLoading}
        closeModal={handleCloseEditModal}
      >
        <div className="w-100 remo-form-input" ref={dropdownRef}>
          <Dropdown
            open={dropdownOpenState.value}
            trigger={
              <Input
                data-testid="JobDetails-7E47E5"
                onChange={onChangeEdit}
                name="position"
                label="Job title"
                type="text"
                onFocus={dropdownOpenState.setTrue}
                value={editState?.position}
                placeholder="Enter job title"
              />
            }
            menu={
              (jobDetailsListQuery?.data?.results.length &&
                (editState?.position
                  ? // eslint-disable-next-line no-unsafe-optional-chaining
                    jobDetailsListQuery.data.results?.filter((f) =>
                      f.name?.toLowerCase().includes(editState.position.toLowerCase())
                    )
                  : // eslint-disable-next-line no-unsafe-optional-chaining
                    jobDetailsListQuery.data.results || []
                ).map(({ id, name, scope_of_work }) => (
                  <button
                    data-testid="JobDetails-E0C036"
                    key={id}
                    type="button"
                    onClick={() => {
                      dropdownOpenState.setFalse()
                      setEditState((prevState) => ({
                        ...prevState,
                        position: name,
                        position_description: scope_of_work,
                      }))
                    }}
                  >
                    {name}
                  </button>
                ))) ||
              []
            }
          />
        </div>
        <div className="remo-form-input">
          <TextArea
            onChange={onChangeEdit}
            label="Scope of work/Job description"
            maxLength={2000}
            rows={10}
            type="text"
            name="position_description"
            value={editState?.position_description}
          />
        </div>
        {hasOccupations && occupations && (
          <div className="remo-form-input">
            <Select
              onChange={onChangeOccupation}
              name="occupation"
              data-testid="JobDetailsForm-9132SB1"
              label="Occupation type"
              placeholder="Select"
              options={occupations.map((o) => ({ value: o.id, label: o.name }))}
              isRequired
              value={editState?.occupation}
            />
          </div>
        )}
        <div className="remo-form-input">
          <Input
            data-testid="JobDetails-84C263"
            onChange={onChangeEdit}
            label="Department"
            type="text"
            name="department"
            value={editState?.department}
          />
        </div>
        <div className="remo-form-input">
          <Select
            label="Line manager"
            loading={isLoading}
            value={editState.direct_manager || null}
            onChange={onChangeManager}
            options={
              directManagers?.map(({ id, name }) => ({
                value: id,
                label: name,
              })) || []
            }
            isClearable
          />
        </div>
        {employee.contract_type === 'contractor' ? (
          <>
            <div className="remo-form-input">
              <Select
                data-testid="JobDetails-D6F47D"
                label="Country"
                onChange={onChangeCountry}
                value={editState.working_country}
                options={countries.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                isDisabled
              />
            </div>
            <div className="remo-form-input">
              <Select
                value={editState.currency}
                isRequired
                label="Contract currency"
                onChange={handleCurrencyChange}
                placeholder="Select"
                options={
                  currencies.map((item) => ({
                    value: item.id,
                    label: `${item.name} (${item.short_code})`,
                  })) || []
                }
              />
              {!editState?.currency && (
                <Typography className="text_regular__14 color_red">Contracrt currency is required</Typography>
              )}
            </div>
            <div className="remo-form-input">
              <Select
                data-testid="JobDetails-DE8AC4"
                onChange={onChangeWage}
                value={editState?.contractor_wage_type}
                label="Wage type"
                name="contractor_wage_type"
                options={[
                  { value: 'hourly', label: 'Hourly' },
                  { value: 'monthly', label: 'Monthly' },
                  { value: 'daily', label: 'Daily' },
                  { value: 'milestone', label: 'Milestone' },
                ]}
              />
            </div>

            {editState.contractor_wage_type === 'hourly' && (
              <div className="remo-form-input">
                <Input
                  data-testid="JobDetails-3E4032"
                  onChange={onChangeEdit}
                  type="number"
                  label="Estimated working hours"
                  name="estimated_working_hours"
                  value={editState?.estimated_working_hours}
                />
              </div>
            )}

            {editState.contractor_wage_type !== 'milestone' && (
              <div className="remo-form-input">
                <CurrencyInput
                  label="Contractor rate"
                  name="contractor_rate"
                  value={editState?.contractor_rate}
                  onChange={setCurrencyChangeEvent(onChangeEdit)}
                />
              </div>
            )}

            {employee?.compensation?.receiving_currency?.short_code && (
              <div className="remo-form-input">
                <Select
                  value={editState.receiving_currency}
                  isRequired
                  label="Receiving currency"
                  loading={isReceivingCurrenciesLoading}
                  onChange={handleReceivingCurrencyChange}
                  placeholder="Select"
                  options={getContractorReceivingCurrencies()}
                />
                {!editState?.receiving_currency && (
                  <Typography className="text_regular__14 color_red">Receiving currency is required</Typography>
                )}
              </div>
            )}

            {editState.contractor_wage_type !== 'milestone' && (
              <div className="remo-form-input">
                <Select
                  data-testid="JobDetails-22C269"
                  label="Payment frequency"
                  value={editState.contractor_payment_frequency}
                  onChange={onPaymentFrequency}
                  options={PAYMENT_FREQUENCY}
                />
              </div>
            )}
          </>
        ) : null}
        <div className="remo-form-input">
          <Checkbox
            data-testid="JobDetails-AF674A"
            name="is_permanent"
            checked={editState.is_permanent}
            onChange={handleCheckChange}
            label="Contract is permanent"
          />
        </div>
        <div className="remo-form-input">
          <DateInput
            className="mb-3"
            disabled={
              (employee.state === 'active' && employee.contract_type === 'full_time_employee') ||
              employee.state === 'inactive'
            }
            onChange={onChangeStartDate}
            label="Start date"
            value={editState?.requested_starts_at}
          />
          {!editState?.requested_starts_at && (
            <Typography className="text_regular__14 color_red">Start date is required</Typography>
          )}
        </div>

        {!editState.is_permanent && (
          <div className="remo-form-input">
            <DateInput onChange={onChangeEndDate} label="End date" onRemove={onRemove} value={editState?.ends_at} />
          </div>
        )}
      </EditModal>
    </div>
  )
}
